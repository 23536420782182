import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { WebsiteMonitoringClient } from "../../api/ToolApiClient";
import useHeaderSwitch from "../../hooks/useHeaderSwitch";
import { Roles } from "../../lib/roles";
import { queryKeys } from "../../react-query/queryKeys";
import { RoutesMenuItem } from "../../routes";
import { AuthenticationService } from "../../services/authenticationService";
import NotificationMenuElement from "./NotificationMenuElement";

const NotificationsMenu = (props: {
  notificationNodes: RoutesMenuItem[];
  showTitles: boolean;
}) => {
  const authenticationService = new AuthenticationService();
  const websiteMonitoringClient = new WebsiteMonitoringClient();

  const [notificationsCount, setNotificationsCount] = useState({
    "gmb-notif": 0,
    "web-notif": 0,
    "website-agent-job-log": 0,
    "campaign-notification": 0,
  });
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    setHasPermission(
      !authenticationService.isUserInRole(Roles.CustomerService)
    );
  }, []);

  const { data } = useQuery({
    queryKey: queryKeys.notificationCount,
    queryFn: () => websiteMonitoringClient.getWebNotifications(),
    enabled: hasPermission,
    refetchInterval: 1000 * 10,
  });

  useEffect(() => {
    if (hasPermission)
      setNotificationsCount({
        "gmb-notif": data ? data.gmbNotifications || 0 : 0,
        "web-notif": data ? data.webNotifications || 0 : 0,
        "website-agent-job-log": data ? data.jobNotifications || 0 : 0,
        "campaign-notification": data ? data.campaignNotifications || 0 : 0,
      });
    else
      setNotificationsCount({
        "gmb-notif": 0,
        "web-notif": 0,
        "website-agent-job-log": 0,
        "campaign-notification": 0,
      });
  }, [data]);

  const { isHeaderVisible } = useHeaderSwitch();

  return (
    <div style={{ display: isHeaderVisible ? "flex" : "block" }}>
      {props.notificationNodes.map((route, index) => {
        return (
          !!route &&
          !!route.subMenuPath &&
          !!route.showInMenu && (
            <div key={index}>
              <NotificationMenuElement
                route={route}
                icon={route.icon}
                count={!!route.name ? notificationsCount[route.name] : 0}
                title={props.showTitles ? route.shortTitle : undefined}
              />
            </div>
          )
        );
      })}
    </div>
  );
};
export default NotificationsMenu;
